import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { PAGE_TITLE_PREFIX } from './constants';

const twelveHoursInMs = 1000 * 60 * 60 * 12;

const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY;

if (!instrumentationKey) {
    // eslint-disable-next-line no-console
    console.warn(
        'Application Insights Instrumentation Key not set, tracking will not be sent.',
    );
}

export const reactPlugin = instrumentationKey && new ReactPlugin();

export const appInsights = instrumentationKey
  && new ApplicationInsights({
      config: {
          instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
          extensions: [reactPlugin],
          // Expiration is the max amount of time before a new session will be logged. Renewal is the time period in which
          // telemetry must be submitted to keep the current session alive. We are interested in tracking whether users
          // return to the same page in order to proceed to the flashcard formatting step after editing the word document output.
          // Editing is likely to take several hours minimum, so we set the renewal the same as the maximum, which is set to
          // the length of a long work day.
          sessionExpirationMs: twelveHoursInMs,
          sessionRenewalMs: twelveHoursInMs,
      },
  });

if (appInsights) {
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((item) => {
        if (item.baseType === 'PageviewData') {
            // eslint-disable-next-line no-param-reassign
            item.baseData.name = item.baseData.name.replace(PAGE_TITLE_PREFIX, '');
        }
    });
}
