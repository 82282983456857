/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        reports: { categoryid: [] },
    },
    reducers: {
        updateReportFilters: {
            reducer(state, { payload }) {
                state.reports = { ...state.reports, ...payload };
            },
        },
        clearNonMainPageReportFilters: {
            reducer(state) {
                state.reports = {
                    selectedTab: state.reports.selectedTab,
                    categoryid: state.reports.categoryid,
                    ...state.reports.isread && { isread: state.reports.isread },
                };
            },
        },
    },
});

export const { updateReportFilters, clearNonMainPageReportFilters } = filterSlice.actions;

export const getFilterState = (store) => store.filter;

export const getReportFilters = createSelector(getFilterState, (filter) => filter.reports);

export default filterSlice.reducer;
