/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';

import { noop } from 'lodash';
import { apiError } from '../api/apiSlice';
import { getEmpNo } from '../user/userSlice';

import WebClient from '../../utils/web-client';
import { trackUserEvent } from '../../utils/tracking';
import { updateUnreadCount } from '../../utils/push-notifications';

export const fetchAwards = createAsyncThunk('award/fetchAwards', async (_, { dispatch, getState }) => {
    try {
        const empNo = getEmpNo(getState());

        const { data } = await WebClient.get(`/FirmDashboard/GetMyDashboardAwards/${empNo}`);

        return data;
    } catch (error) {
        dispatch(apiError('Something went wrong while retrieving your KudOs queue', error));
        throw error;
    }
});

export const markAwardsRead = createAsyncThunk('award/markAwardsRead', async (_, { dispatch, getState }) => {
    try {
        const empNo = getEmpNo(getState());
        await WebClient.post(`/FirmDashboard/AwardQueue/MarkAwardsRead/${empNo}`);
        updateUnreadCount(empNo);
    } catch (error) {
        dispatch(apiError('Unable to mark KudOs read at this time', error));
        throw error;
    }
});

export const awardSlice = createSlice({
    name: 'award',
    initialState: {
        awards: [],
        loading: false,
    },
    reducers: {
        updateItemSuccess: {
            reducer(state, { payload }) {
                state.awards = state.awards.map((item) => (item.AwardID === payload.awardId ? ({
                    ...item,
                    ...payload.updates,
                }) : item));
            },
            prepare(awardId, updates) {
                return { payload: { awardId, updates } };
            },
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAwards.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAwards.fulfilled, (state, { payload }) => {
            state.awards = payload;
            state.loading = false;
        });
        builder.addCase(markAwardsRead.fulfilled, (state) => {
            state.awards = state.awards.map((award) => ({
                ...award,
                Read: 1,
            }));
        });
    },
});
export const { updateItemSuccess } = awardSlice.actions;
export const markAwardAsRead = createAsyncThunk('award/markAwardAsRead', async ({ awardId, onSuccess = noop }, { dispatch, getState }) => {
    try {
        const empNo = getEmpNo(getState());
        await WebClient.post(`/FirmDashboard/AwardQueue/SetAwardRead/${empNo}/${awardId}`);
        dispatch(updateItemSuccess(awardId, { Read: 1 }));
        trackUserEvent('Marked award read', { awardId });
        onSuccess();
        updateUnreadCount(empNo);
    } catch (error) {
        dispatch(apiError('Unable to mark KudO as read at this time', error));
        throw error;
    }
});

export const getAwardState = (state) => state.award;

export const getAwards = createSelector(getAwardState, (awardState) => awardState.awards);

export const getAwardsAreLoading = createSelector(getAwardState, (awardState) => awardState.loading);

export default awardSlice.reducer;
