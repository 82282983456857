export default {
    white: '#ffffff',
    whiteDark: '#F4F4F5',
    black: '#2F3135',
    blackRGB: '47,49,53',
    mist: '#CFDAE6',
    mistLight: '#D8E3F0',
    grey: '#717271',
    greyRGB: '113,114,113',
    greyDark: '#6C6E71',
    greyLight: '#CFDAE6',
    greyLightest: '#F0F4F7',
    regalBlue: '#1D2E4B',
    tAndPink: '#E60073',
    brightPink: '#FF0080',
    midnight: '#16324D',
    midnightLight: '#162F4A',
    error: '#E00E0E',
    success: '#D5E1BE',
    blueSky: '#0077C7',
    blueSoft: '#E4F1F9',
    blueLight: '#4C9FD7',
    aubergine: '#333447',
    iron: '#D7D9DB',
    ironRGB: '215,217,219',
    concrete1: '#F5F3F2',
    concrete2: '#00677F',
    concrete3: '#E8E4DF',
    green: '#7A9C49',
    moss: '#ACC37E',
    mossRGB: '172,195,126',
    rubine: '#D50057',
    rubineRGB: '213, 0, 87',
    orange: '#FF5C35',
    yellow: '#FDB913',
    yellowLight: '#FED571',
    ultraViolet: '#5F4B8B',
    tealDark: '#00677F',
    fireBush: '#E59332',
    fireBushSoft: '#EBCA9F',
};
