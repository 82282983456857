/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false,
        credentials: {
            groups: [],
            authenticatedEmpNo: null,
        },
    },
    reducers: {
        getTokenSuccess: {
            reducer(state, { payload }) {
                const { authenticatedEmpNo, groups } = payload;
                state.isAuthenticated = true;
                state.credentials = {
                    groups,
                    authenticatedEmpNo,
                };
            },
            prepare(authenticatedEmpNo, groups) {
                return {
                    payload: { authenticatedEmpNo, groups },
                };
            },
        },
    },
});

export const { getTokenSuccess } = authSlice.actions;

export const getAuthState = (state) => state.auth;

export const getIsAuthenticated = createSelector(
    getAuthState,
    (authState) => authState.isAuthenticated,
);

export const getCredentials = createSelector(
    getAuthState,
    (authState) => authState.credentials,
);

export default authSlice.reducer;
