/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

import { trackException } from '../../utils/tracking';

const apiSlice = createSlice({
    name: 'api',
    initialState: {},
    reducers: {
        error: {
            reducer(state, { payload }) {
                const { errorMessage, errorContext } = payload;

                if (errorMessage || errorContext) {
                    trackException(errorMessage, errorContext);
                }

                state.errorMessage = errorMessage;
                state.errorContext = errorContext;
                state.successMessage = '';
            },
            prepare(errorMessage, errorContext) {
                return {
                    payload: { errorMessage, errorContext },
                };
            },
        },
        success(state, { payload }) {
            state.errorMessage = '';
            state.errorContext = null;
            state.successMessage = payload;
        },
        clear(state) {
            state.errorMessage = '';
            state.errorContext = null;
            state.successMessage = '';
        },
    },
});

const { error, success, clear } = apiSlice.actions;

export { error as apiError, success as apiSuccess, clear as clearApiMessages };

export const getApiState = (state) => state.api;

export const getErrorMessage = createSelector(
    getApiState,
    (api) => api.errorMessage,
);

export const getSuccessMessage = createSelector(
    getApiState,
    (api) => api.successMessage,
);

export default apiSlice.reducer;
