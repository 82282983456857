import React, { Suspense } from 'react';
import {
    Navigate,
    Route,
} from 'react-router-dom';

import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';
import { SentryRoutes } from '../../utils/sentry';
import MainContainer from '../../components/main-container';

const PublicLayout = () => (
    <Suspense fallback={<div />}>
        <MainContainer>
            <SentryRoutes>
                <Route path="/*" element={<Navigate replace to="/dashboard" />} />
            </SentryRoutes>
        </MainContainer>
    </Suspense>
);

export default withRoot(PublicLayout, themer);
