/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';

import { noop } from 'lodash';
import React from 'react';
import { apiError, apiSuccess } from '../api/apiSlice';
import { getEmpNo } from '../user/userSlice';
import WebClient from '../../utils/web-client';
import { trackUserEvent } from '../../utils/tracking';
import { BOOLEANS } from '../../utils/constants';
import { updateUnreadCount } from '../../utils/push-notifications';

export const ITEM_KEYS = {
    TASK: 'task',
    REPORT: 'report',
};

export const fetchItems = createAsyncThunk('item/fetchItems', async ({ itemType, params }, { dispatch, getState }) => {
    try {
        const empNo = getEmpNo(getState());
        const { data } = await WebClient.post(`/FirmDashboard/mydashboardqueue/${empNo}`, params);
        return { itemType, data };
    } catch (error) {
        dispatch(apiError('Something went wrong while retrieving your queue', error));
        throw error;
    }
});

export const markSectionAsRead = createAsyncThunk('item/markSectionAsRead', async ({ itemType, sectionId }, { dispatch, getState }) => {
    try {
        const empNo = getEmpNo(getState());
        await WebClient.post(`/FirmDashboard/mydashboardqueue/MarkMyDashboardQueueSectionRead/${empNo}/${sectionId}`);
        updateUnreadCount(empNo);
        return { itemType };
    } catch (error) {
        dispatch(apiError('Something went wrong while attemping to mark the items as read', error));
        throw error;
    }
});

const itemSlice = createSlice({
    name: 'item',
    initialState: {
        [ITEM_KEYS.TASK]: {
            total: 0,
            list: [],
            fromAuthors: [],
            loading: false,
        },
        [ITEM_KEYS.REPORT]: {
            total: 0,
            list: [],
            fromAuthors: [],
            loading: false,
        },
    },
    reducers: {
        updateItemSuccess: {
            reducer(state, { payload }) {
                state[payload.itemType].list = state[payload.itemType].list.map((item) => (item.ItemID === payload.itemId ? ({
                    ...item,
                    ...payload.updates,
                }) : item));
            },
            prepare(itemType, itemId, updates) {
                return { payload: { itemType, itemId, updates } };
            },
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchItems.pending, (state, action) => {
            state[action.meta.arg.itemType] = { ...state[action.meta.arg.itemType], loading: true };
        });
        builder.addCase(fetchItems.fulfilled, (state, { payload }) => {
            state[payload.itemType] = {
                total: payload.data.Total,
                list: payload.data.Items ?? [],
                fromAuthors: payload.data.FromAuthors ?? [],
                loading: false,
            };
        });
        builder.addCase(markSectionAsRead.fulfilled, (state, { payload }) => {
            state[payload.itemType].list = state[payload.itemType].list.map((x) => ({ ...x, isRead: true }));
        });
    },
});

export const { updateItemSuccess } = itemSlice.actions;

export const markItemAsRead = createAsyncThunk('item/markAsRead', async ({ itemType, itemId, onSuccess = noop }, { dispatch, getState }) => {
    try {
        const empNo = getEmpNo(getState());
        await WebClient.post(`/FirmDashboard/mydashboardqueue/SetMyDashboardQueueItemRead/${empNo}/${itemId}`, { value: BOOLEANS.TRUE });
        dispatch(updateItemSuccess(itemType, itemId, { isRead: true }));
        trackUserEvent(`Marked ${itemType} read`, { itemId });
        onSuccess();
        updateUnreadCount(empNo);
    } catch (error) {
        dispatch(apiError(`Unable to mark ${itemType} as read at this time`, error));
        throw error;
    }
});

export const sendItemAsEmail = createAsyncThunk('item/sendItemAsEmail', async ({ itemId, onSuccess = noop }, { dispatch, getState }) => {
    try {
        const empNo = getEmpNo(getState());
        await WebClient.post(`/FirmDashboard/SendMyDashboardQueueItemEmail/${empNo}/${itemId}`);
        trackUserEvent(`Item ${itemId} sent as email`, { itemId });
        dispatch(apiSuccess('The email has been sent to your inbox'));
        onSuccess();
    } catch (error) {
        dispatch(apiError((<>The email send failed. Please notify the <a href="mailto:servicedesk@orrick.com">Service Desk</a>.</>), error));
        throw error;
    }
});

export const markItemAsComplete = createAsyncThunk('item/markAsComplete', async ({ itemType, itemId, onSuccess = noop }, { dispatch, getState }) => {
    try {
        const empNo = getEmpNo(getState());
        await WebClient.post(`/FirmDashboard/mydashboardqueue/SetMyDashboardQueueItemComplete/${empNo}/${itemId}`, { value: BOOLEANS.TRUE });
        trackUserEvent(`Marked ${itemType} complete`, { itemId });
        dispatch(updateItemSuccess(itemType, itemId, { Complete: true }));
        onSuccess();
        updateUnreadCount(empNo);
    } catch (error) {
        dispatch(apiError(`Unable to mark ${itemType} as complete at this time`, error));
        throw error;
    }
});

export const markItemAsHidden = createAsyncThunk('item/markAsHidden', async ({ itemType, itemId, onSuccess = noop }, { dispatch, getState }) => {
    try {
        const empNo = getEmpNo(getState());
        await WebClient.post(`/FirmDashboard/mydashboardqueue/HideMyDashboardQueueItem/${empNo}/${itemId}`, { value: BOOLEANS.TRUE });
        dispatch(updateItemSuccess(itemType, itemId, { Hide: true }));
        trackUserEvent(`Removed ${itemType}`, { itemId });
        onSuccess();
        updateUnreadCount(empNo);
    } catch (error) {
        dispatch(apiError(`Unable to mark ${itemType} as removed at this time`, error));
        throw error;
    }
});

export const markItemAsOpen = createAsyncThunk('item/markAsOpen', async ({ itemType, itemId, onSuccess = noop }, { dispatch, getState }) => {
    try {
        const empNo = getEmpNo(getState());
        await WebClient.post(`/FirmDashboard/mydashboardqueue/HideMyDashboardQueueItem/${empNo}/${itemId}`, { value: BOOLEANS.FALSE });
        await WebClient.post(`/FirmDashboard/mydashboardqueue/SetMyDashboardQueueItemComplete/${empNo}/${itemId}`, { value: BOOLEANS.FALSE });
        dispatch(updateItemSuccess(itemType, itemId, { Hide: false, Complete: false }));
        trackUserEvent(`Opened ${itemType}`, { itemId });
        onSuccess();
        updateUnreadCount(empNo);
    } catch (error) {
        dispatch(apiError(`Unable to mark ${itemType} as removed at this time`, error));
        throw error;
    }
});

export const getItemState = (store) => store.item;

export const getItems = createSelector(
    getItemState,
    (items) => (itemType) => items[itemType].list,
);

export const getItemsTotal = createSelector(
    getItemState,
    (items) => (itemType) => items[itemType].total,
);

export const getItemsFromAuthors = createSelector(
    getItemState,
    (items) => (itemType) => items[itemType].fromAuthors,
);

export const getAreItemsLoading = createSelector(
    getItemState,
    (items) => (itemType) => items[itemType].loading,
);

export default itemSlice.reducer;
