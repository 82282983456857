import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './app-insights';

function withTracking(component) {
    if (!reactPlugin) return component;

    return withAITracking(reactPlugin, component);
}

export default withTracking;
