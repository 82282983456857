import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import PublicLayout from '../layouts/public';
import { SentryRoutes } from '../utils/sentry';
import AuthWrapper from '../components/auth-wrapper';
import { trackUserEvent, setTrackingUser } from '../utils/tracking';
import withTracking from '../utils/withTracking';
import withErrorBoundary from '../utils/withErrorBoundary';

const AdminLayout = lazy(() => import('../layouts/admin'));

const onAuthenticated = (account, empNo, loginType) => {
    setTrackingUser(account.username, account.name, empNo);
    trackUserEvent('Login succeeded', { loginType });
};

const onLoginRedirect = () => {
    trackUserEvent('Attempting login via redirect');
};

const App = () => (
    <Suspense fallback={<div />}>
        <SentryRoutes>
            <Route
                path="/dashboard/*"
                element={(
                    <AuthWrapper
                        onAuthenticated={onAuthenticated}
                        onLoginRedirect={onLoginRedirect}
                    >
                        <AdminLayout />
                    </AuthWrapper>
                )}
            />
            <Route path="/*" element={<PublicLayout />} />
        </SentryRoutes>
    </Suspense>
);

export default withTracking(withErrorBoundary(App));
