export const CLASS_TYPE_IDS = {
    REPORT: Number(process.env.REACT_APP_REPORT_TYPE_ID),
    UPDATE: Number(process.env.REACT_APP_UPDATE_TYPE_ID),
    SECTORPGNEWS: Number(process.env.REACT_APP_SECTORPGNEWS_TYPE_ID),
    ALERT: Number(process.env.REACT_APP_ALERT_TYPE_ID),
};

export const SECTION_IDS = {
    TASKS_NOTIFICATIONS: Number(process.env.REACT_APP_TASKS_SECTION_ID),
    REPORTS_UPDATES: Number(process.env.REACT_APP_REPORTS_SECTION_ID),
};

export const ITEM_TYPE_IDS = {
    MONTHLY_BILLING_CERTIFICATION: Number(process.env.REACT_APP_MONTHLY_BILLING_CERT_ID),
};

export const BOOLEANS = {
    TRUE: 1,
    FALSE: 0,
};

export const PAGE_TITLE_PREFIX = 'OrrickOne - ';
