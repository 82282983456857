import { base64ToArrayBuffer as convertVapidKey } from 'base64-u8array-arraybuffer';
import WebClient from './web-client';
import { trackException } from './tracking';

let serviceWorkerRegistration = null;

const getServiceWorkerRegistration = async () => {
    if (!serviceWorkerRegistration) {
        serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
    }
};

export const areNotificationsSupported = async () => {
    await getServiceWorkerRegistration();
    return ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window && serviceWorkerRegistration);
};

const getPushSubscription = async () => {
    await getServiceWorkerRegistration();
    let subscription = await serviceWorkerRegistration.pushManager.getSubscription();
    if (subscription == null) {
        subscription = await serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertVapidKey(process.env.REACT_APP_VAPID_PUBLIC_KEY),
        });
    }
    return subscription;
};

export const getPushSettings = async (empNo) => {
    const subscription = await getPushSubscription();
    const { data } = await WebClient.post(`/FirmDashboard/GetPushSettings/${empNo}`, subscription.toJSON());
    return data;
};

export const managePushSubscription = async (empNo, notificationEnabled) => {
    const subscription = await getPushSubscription();

    const appBadgeCapable = !!navigator.setAppBadge;

    const request = {
        pushSubscriptionJson: subscription.toJSON(),
        notificationEnabled,
        appBadgeCapable,
    };

    await WebClient.put(`/FirmDashboard/ManagePush/${empNo}`, request);
};

export const updateUnreadCount = async (empNo) => {
    if (navigator.setAppBadge) {
        try {
            const { data } = await WebClient.get(`/FirmDashboard/GetMyUnreadCount/${empNo}`);
            navigator.setAppBadge(data.Total);
        } catch (e) {
            trackException('Error occurred retrieving unread count', e);
        }
    }
};

export const createPushSubscription = async (empNo) => {
    await managePushSubscription(empNo, true);
};
