import Axios from 'axios';

const internals = {
    host: process.env.REACT_APP_API_HOST,
    prefix: process.env.REACT_APP_API_PREFIX,
    getApiBase: () => {
        const { host, prefix } = internals;

        return `${host}${prefix}`;
    },
};

const client = Axios.create({
    baseURL: internals.getApiBase(),
    responseType: 'json',
    headers: { common: {} },
});

// eslint-disable-next-line consistent-return
client.interceptors.response.use((response) => response, (error) => {
    const originalRequest = error.request;

    if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        window.location.reload();
    } else {
        // This is important, so that unhandled cases make axios throw errors
        return Promise.reject(error);
    }
});

export default client;

client.batch = (reqs) => {
    const { prefix } = internals;

    const requests = reqs.map((request) => ({
        ...request,
        path: `${prefix}${request.path}`,
    }));

    return client.post(internals.getApiBase(), { requests });
};

client.updateAuth = (newToken) => {
    const headers = client.defaults.headers.common;

    if (!newToken) {
        return delete headers.authorization;
    }

    headers.authorization = `Bearer ${newToken}`;

    return true;
};
