import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// REDUX
import { Provider } from 'react-redux';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import { configureStore } from '@reduxjs/toolkit';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import reducers from './reducers';

import App from './routes';

import 'sanitize.css/sanitize.css';
import './index.css';
import ScrollToTop from './components/scroll-to-top';
import { init as sentryInit } from './utils/sentry';

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'meta'],
};
const persistedReducer = persistReducer(persistConfig, reducers());

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'api/error'],
            ignoredPaths: ['api.errorContext'],
        },
    }),
});

const persistedStore = persistStore(store);

const container = document.getElementById('root');
const root = createRoot(container);

// initialize sentry
sentryInit();

root.render(
    <Provider store={store}>
        <PersistGate
            loading={null}
            persistor={persistedStore}
        >
            <BrowserRouter>
                <ScrollToTop />
                <App store={store} />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
