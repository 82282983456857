/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';

import WebClient from '../../utils/web-client';
import { apiError } from '../api/apiSlice';

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async (userId, { dispatch }) => {
    try {
        const { data } = await WebClient.get(`/PersonnelInfo/${userId}`);
        return data.Personnel;
    } catch (error) {
        dispatch(apiError('Unable to retrieve user information', error));
        throw error;
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => { state.user = payload; });
    },
});

export const getUser = createSelector((state) => state.user, (userState) => userState.user);

export const getEmpNo = createSelector(getUser, (user) => user?.EmpNo);

export default userSlice.reducer;
