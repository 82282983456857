import { styled } from '@mui/material/styles';
import Colors from '../../styles/colors';

const MainContainer = styled('main')(({ theme }) => ({
    // split the background color vertically
    background: `linear-gradient(to bottom, ${Colors.aubergine} 380px, ${Colors.concrete1} 380px)`,
    paddingBottom: '80px',

    [theme.breakpoints.down('md')]: {
        background: `linear-gradient(to bottom, ${Colors.aubergine} 550px, ${Colors.concrete1} 550px)`,
    },
}));

export default MainContainer;
