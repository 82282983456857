/* eslint-disable no-param-reassign */
import { appInsights } from './app-insights';

// Always place at the end of a handler. Seems to interrupt proper behavior of some components if placed earlier.
export const trackUserEvent = (name, customProperties = null) => {
    if (appInsights) appInsights.trackEvent({ name }, customProperties);
};

export const trackException = (message, exception) => {
    if (appInsights) appInsights.trackException({ exception }, { message });
};

export const setTrackingUser = (user, displayName, empNo) => {
    if (appInsights) {
        appInsights.setAuthenticatedUserContext(user, null, true);
        const telemetryInitializer = (envelope) => {
            envelope.data.userDisplayName = displayName;
            envelope.data.userEmpNo = empNo;
        };

        appInsights.addTelemetryInitializer(telemetryInitializer);
    }
};
