import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    AuthenticatedTemplate,
    MsalProvider,
    useMsalAuthentication,
    UnauthenticatedTemplate,
    useMsal,
} from '@azure/msal-react';
import {
    PublicClientApplication,
    InteractionType,
    InteractionRequiredAuthError,
    ClientAuthError,
    BrowserAuthError,
} from '@azure/msal-browser';
import { useDispatch } from 'react-redux';
import noop from 'lodash/noop';

import { apiError } from '../../features/api/apiSlice';
import WebClient from '../../utils/web-client';
import parseJwt from '../../utils/parse-jwt';
import { getTokenSuccess } from '../../features/auth/authSlice';

const configuration = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        authority: process.env.REACT_APP_AUTH_AUTHORITY,
    },
};

const pca = new PublicClientApplication(configuration);

const AuthWrapperView = ({ children, onLoginRedirect, onAuthenticated }) => {
    const request = {};
    const dispatch = useDispatch();

    const { login, result, error } = useMsalAuthentication(
        InteractionType.Silent,
        request,
    );

    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(true);
    const { instance, accounts } = useMsal();

    useEffect(() => {
        if (
            error instanceof InteractionRequiredAuthError
            || error instanceof ClientAuthError
        ) {
            sessionStorage.setItem('loginType', 'Redirect');
            if (onLoginRedirect) onLoginRedirect();
            login(InteractionType.Redirect, request)
                .catch((redirectError) => {
                    setErrorMessage(redirectError);
                    login(InteractionType.Popup, request).catch(
                        (popupError) => {
                            setErrorMessage(popupError.errorMessage);
                        },
                    );
                })
                .finally(() => setLoading(false));
        } else if (error instanceof BrowserAuthError) {
            window.location.reload();
        } else if (error) {
            dispatch(apiError('Something went wrong with authentication, please try again later', error));
        }
    }, [error]);

    useEffect(() => {
        if (result) {
            setLoading(false);
            instance.setActiveAccount(result.account);
        }
    }, [result]);

    useEffect(() => {
        const getAuthToken = async () => {
            if (accounts.length > 0) {
                const accessToken = await instance.acquireTokenSilent({
                    account: accounts[0],
                    scopes: [`api://${process.env.REACT_APP_API_HOST_RESOURCE_ID}/user_impersonation`],
                });

                WebClient.updateAuth(accessToken.accessToken);

                const decoded = parseJwt(accessToken.accessToken);
                dispatch(getTokenSuccess(decoded.empno, decoded.groups));
                const loginType = sessionStorage.getItem('loginType') ?? 'Silent';
                sessionStorage.removeItem('loginType');
                if (onAuthenticated) onAuthenticated(accounts[0], decoded.empno, loginType);
            }
        };

        getAuthToken();
    }, [accounts]);

    let message = 'Unauthenticated';

    if (loading) message = 'Loading';
    else if (errorMessage) message = errorMessage;

    return (
        <>
            <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
            {result ? null : (
                <UnauthenticatedTemplate>
                    <p>{message}</p>
                </UnauthenticatedTemplate>
            )}
        </>
    );
};

AuthWrapperView.propTypes = {
    children: PropTypes.object.isRequired,
    onLoginRedirect: PropTypes.func.isRequired,
    onAuthenticated: PropTypes.func.isRequired,
};

const AuthWrapper = ({ onAuthenticated, onLoginRedirect, children }) => (
    <MsalProvider instance={pca}>
        <AuthWrapperView
            onAuthenticated={onAuthenticated}
            onLoginRedirect={onLoginRedirect}
        >
            {children}
        </AuthWrapperView>
    </MsalProvider>
);

AuthWrapper.propTypes = {
    children: PropTypes.object.isRequired,
    onLoginRedirect: PropTypes.func,
    onAuthenticated: PropTypes.func,
};

AuthWrapper.defaultProps = {
    onLoginRedirect: noop,
    onAuthenticated: noop,
};

export default AuthWrapper;
