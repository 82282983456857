import React from 'react';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './app-insights';
import ErrorBoundary from '../components/error-fallback';

function withErrorBoundary(component) {
    const wrapped = (props) => (
        <AppInsightsErrorBoundary onError={() => <ErrorBoundary />} appInsights={reactPlugin}>
            {component(props)}
        </AppInsightsErrorBoundary>
    );

    return wrapped;
}

export default withErrorBoundary;
